p.MsoNormal, li.MsoNormal, div.MsoNormal {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8.0pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri",sans-serif;
}

p.MsoFootnoteText, li.MsoFootnoteText, div.MsoFootnoteText {
    margin: 0cm;
    font-size: 10.0pt;
    font-family: "Times New Roman",serif;
}

p.MsoCommentText, li.MsoCommentText, div.MsoCommentText {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8.0pt;
    margin-left: 0cm;
    font-size: 10.0pt;
    font-family: "Calibri",sans-serif;
}

span.MsoFootnoteReference {
    vertical-align: super;
}

p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8.0pt;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri",sans-serif;
}

p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri",sans-serif;
}

p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri",sans-serif;
}

p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8.0pt;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri",sans-serif;
}

span.FootnoteTextChar {
    font-family: "Times New Roman",serif;
}

.MsoChpDefault {
    font-family: "Calibri",sans-serif;
}

.MsoPapDefault {
    margin-bottom: 8.0pt;
    line-height: 107%;
}
/* List Definitions */
ol {
    margin-bottom: 0cm;
}

ul {
    margin-bottom: 0cm;
}