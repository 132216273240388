.sticky {
  position: relative;
  background: #ffcc;
  box-shadow: -2px 2px 2px rgba(0,0,0,0.3);
  margin: 0;
  height: 100%;
  width: 100%;
}
.sticky:before, .sticky:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.sticky:after {
  border-bottom: solid 8px #dddd33;
  border-left: solid 8px #dddd33;
  border-right: solid 8px #fafafa;
  border-top: solid 8px #fafafa;
}
.stickyFrame{
  width: 100%;
  height: 100%;
  margin-left: 15px;
  margin-right: 35px;
  margin-bottom: 20px;
}
.container{
  display: inline-block;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 100%;
}
.headerWrapper, .footerWrapper, .flexWrapper{
  display: flex;
  justify-content: space-between;
  color: grey;
  align-items: center;
}

.preview{
  height: 125px;
  width: 155px;
  white-space: pre-wrap;
  word-wrap: break-word; 
  overflow: hidden;
}

.hoverContent {
  visibility: hidden;
}

.hoverContent:hover {
  visibility: visible;
}

.hoverTarget:hover .hoverContent {
  visibility: visible;
}

.watermarkContainer {
  position: relative;
  overflow: hidden;
}

.watermarkContainer .watermarkText {
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 60px;
  pointer-events: none;
  transform: rotate(-45deg);
  opacity: 0.4;
  text-transform: capitalize;
}